import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';
import {
  Auth0ProviderConfig,
  CustomAuth0Provider,
} from '@fresh-stack/frontend-commons';
import { LicenseInfo } from '@mui/x-license';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const config: Auth0ProviderConfig = {
  environment: import.meta.env.VITE_ENVIRONMENT ?? 'local',
  domain: import.meta.env.VITE_AUTH_DOMAIN || '',
  clientId: import.meta.env.VITE_AUTH_CLIENT_ID || '',
  audience: import.meta.env.VITE_AUTH_AUDIENCE || '',
  organization: import.meta.env.VITE_AUTH_ORGANIZATION || '',
};

LicenseInfo.setLicenseKey(
  import.meta.env.VITE_MUI_X_LICENSE_KEY || 'missing-key',
);

root.render(
  <CustomAuth0Provider config={config}>
    <App />
  </CustomAuth0Provider>,
);
