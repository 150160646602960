import {
  AsyncResult,
  DateOrStringOrNumber,
  formatCurrencyAmount,
  head,
  keyBy,
  reduce,
  sortBy,
} from '@fresh-stack/fullstack-commons';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import PendingIcon from '@mui/icons-material/HourglassTop';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import {
  DataGridPro,
  GridColDef,
  GridRowSelectionModel,
  GridRowsProp,
} from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { CustomFieldDefinition } from '../../types';
import { FONTS, appColors } from '../theme';

export type TransactionPagination = {
  readonly page: number;
  readonly pageSize: 25 | 50 | 100;
};

const ATTR_FIELD_PREFIX = 'CUSTOM_ATTR' as const;

const DATA_FIELD_NAMES = [
  'date',
  'accountName',
  'amount',
  'transactionType',
  'nameOrDescription',
] as const;

const EXTRA_FIELD_NAMES = ['checkbox'] as const;

type TransactionDataField = (typeof DATA_FIELD_NAMES)[number];

type TransactionField =
  | TransactionDataField
  | (typeof EXTRA_FIELD_NAMES)[number];

export type TransactionSorting = {
  readonly by: TransactionDataField;
  readonly direction: 'asc' | 'desc';
};

const isTransactionDataField = (
  input: string | undefined,
): input is TransactionDataField => {
  return !!input && DATA_FIELD_NAMES.includes(input as TransactionDataField);
};

type AttributeFieldName = `${typeof ATTR_FIELD_PREFIX}__${string}`;

const getAttrFieldName = (classId: string): AttributeFieldName =>
  `${ATTR_FIELD_PREFIX}__${classId}`;

interface TransactionModelBase {
  readonly transactionId: string;
  readonly date: DateOrStringOrNumber;
  readonly amount: number;
  readonly transactionType: string;
  readonly isPending: boolean;
  readonly isRejected: boolean;
  readonly nameOrDescription: string;
  readonly isoCurrencyCode: string;
  readonly accountName: string;
}

interface TransactionRowModel extends TransactionModelBase {
  readonly [key: AttributeFieldName]: string;
}

interface TransactionRowParams extends TransactionModelBase {
  readonly attributes: {
    readonly class: {
      readonly id: string;
      readonly name: string;
    };
    readonly value?: {
      readonly id: string;
      readonly name: string;
      readonly isOverride: boolean;
    };
  }[];
}

const ATTRIBUTE_COL = 'attribute-col';

const baseColumns: readonly (GridColDef<TransactionRowModel> & {
  readonly field: TransactionField;
})[] = [
  {
    field: 'date',
    headerName: 'Date',
    width: 150,
    renderCell: (params) => (
      <Box display="flex" alignItems={'center'} gap={1}>
        <Typography>
          {format(new Date(params.row.date), 'dd/MM/yyyy')}
        </Typography>
        {params.row.isPending ? (
          <Tooltip title="Pending">
            <PendingIcon color="primary" fontSize="small" />
          </Tooltip>
        ) : null}
        {params.row.isRejected ? (
          <Tooltip title="Rejected">
            <DoNotDisturbIcon color="warning" fontSize="small" />
          </Tooltip>
        ) : null}
      </Box>
    ),
  },
  { field: 'accountName', headerName: 'Account Name', minWidth: 175, flex: 1 },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 150,
    type: 'number',
    renderCell: (params) =>
      formatCurrencyAmount({
        currency: params.row.isoCurrencyCode,
        value: params.value,
      }),
  },
  { field: 'transactionType', headerName: 'Transaction type', width: 150 },
  {
    field: 'nameOrDescription',
    headerName: 'Description',
    minWidth: 300,
    flex: 2,
  },
];

const AttributeHeader = ({
  colDef,
}: {
  readonly colDef: GridColDef | undefined;
}) => {
  return (
    <div style={{ padding: '0', lineHeight: 'normal' }}>
      <div>{colDef?.headerName}</div>
      <div style={{ color: 'grey', fontSize: '10px', marginTop: '4px' }}>
        Custom field
      </div>
    </div>
  );
};

export const TransactionTable = ({
  customFields,
  transactions,
  rowCount,
  isLoading,
  paginationModel,
  sortModel,
  onPaginationChange,
  onSortChange,
  onSelectionChanged,
}: {
  readonly customFields: CustomFieldDefinition[];
  readonly transactions: readonly TransactionRowParams[];
  readonly rowCount: number;
  readonly setAttributeValue: ({
    transactionId,
    attributeId,
    valueId,
  }: {
    readonly transactionId: string;
    readonly attributeId: string;
    readonly valueId: string;
  }) => AsyncResult<'success', 'unknown'>;
  readonly clearAttributeValue: ({
    transactionId,
    attributeId,
  }: {
    readonly transactionId: string;
    readonly attributeId: string;
  }) => AsyncResult<'success', 'unknown'>;
  readonly setError: (error: string) => void;
  readonly isLoading: boolean;
  readonly paginationModel: TransactionPagination;
  readonly sortModel: TransactionSorting;
  readonly onPaginationChange: (input: TransactionPagination) => void;
  readonly onSortChange: (input: TransactionSorting) => void;
  readonly onSelectionChanged: (input: {
    readonly transactionIds: string[];
    readonly isFullPageSelected: boolean;
  }) => void;
}) => {
  const attrValuesByTxIdByClassId = useMemo(
    () =>
      transactions.reduce(
        (acc, transaction) => {
          for (const attr of transaction.attributes) {
            if (!acc[attr.class.id]) {
              acc[attr.class.id] = {
                className: attr.class.name,
                valuesByTx: {},
              };
            }
            acc[attr.class.id].valuesByTx[transaction.transactionId] = attr;
          }
          return acc;
        },
        {} as Record<
          string,
          {
            readonly className: string;
            readonly valuesByTx: Record<
              string,
              TransactionRowParams['attributes'][number]
            >;
          }
        >,
      ),
    [transactions],
  );

  const attributeColumns: GridColDef[] = sortBy(
    Object.entries(attrValuesByTxIdByClassId).map(
      ([classId, { className, valuesByTx }]) => {
        const x: GridColDef & { readonly classId: string } = {
          classId: classId,
          field: getAttrFieldName(classId),
          headerName: className,
          minWidth: 200,
          flex: 1,
          sortable: false,
          renderCell: (params) => {
            const attr = valuesByTx[params.id];
            return (
              <Stack
                direction="row"
                justifyContent="space-between"
                width={'100%'}
                alignItems="center"
              >
                <div>{attr?.value?.name ?? ''}</div>
                {attr?.value?.isOverride && (
                  <Tooltip title="Value manually set">
                    <BuildCircleIcon style={{ color: appColors.lightGrey }} />
                  </Tooltip>
                )}
              </Stack>
            );
          },
          renderHeader: AttributeHeader,
        };
        return x;
      },
    ),
    (x) => customFields.findIndex((cf) => cf.classId === x.classId),
  ).map((colDef, index) => ({
    ...colDef,
    headerClassName: index === 0 ? ATTRIBUTE_COL : '',
    cellClassName: index === 0 ? ATTRIBUTE_COL : '',
  }));

  const columns = [...baseColumns, ...attributeColumns];

  const rows: GridRowsProp<TransactionRowModel> = transactions.map(
    (transaction) => {
      const valuesByClassId = keyBy(
        transaction.attributes,
        (value) => value.class.id,
      );
      const attributeRows = reduce(
        attrValuesByTxIdByClassId,
        (acc, _attr, classId) => {
          acc[getAttrFieldName(classId)] =
            valuesByClassId[classId].value?.id || '';
          return acc;
        },
        {} as Record<string, string>,
      );

      return {
        id: transaction.transactionId,
        ...transaction,
        date: new Date(transaction.date),
        ...attributeRows,
      };
    },
  );

  const handlePaginationChange = (input: TransactionPagination) => {
    if (input.pageSize !== paginationModel.pageSize) {
      onPaginationChange({
        page: 0,
        pageSize: input.pageSize,
      });
    } else if (input.page !== paginationModel.page) {
      onPaginationChange(input);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <DataGridPro
        checkboxSelection
        sx={{
          bgcolor: 'white',
          boxShadow: 3,
          padding: 1,
          maxHeight: '65vh',
          [`& .${ATTRIBUTE_COL}`]: {
            borderLeft: `2px solid ${appColors.lightGrey}`,
          },
        }}
        loading={isLoading}
        rowCount={rowCount}
        pagination
        paginationMode="server"
        filterMode="server"
        disableColumnFilter={true}
        rows={rows}
        columns={columns}
        sortingMode="server"
        onRowSelectionModelChange={(x: GridRowSelectionModel) => {
          const payload = {
            transactionIds: x.map((id) => id.toString()),
            isFullPageSelected:
              x.length === rows.length && x.length !== rowCount && rowCount > 0,
          };
          onSelectionChanged(payload);
        }}
        sortModel={[
          {
            field: sortModel.by,
            sort: sortModel.direction,
          },
        ]}
        onSortModelChange={(sorting) => {
          const option = head(sorting);
          const fieldName = option?.field ?? sortModel.by;
          const direction =
            option?.sort ?? (sortModel.direction === 'asc' ? 'desc' : 'asc');
          if (
            isTransactionDataField(fieldName) &&
            (fieldName !== sortModel.by || option?.sort !== sortModel.direction)
          ) {
            onSortChange({
              by: fieldName,
              direction: direction,
            });
          }
        }}
        paginationModel={paginationModel}
        onPaginationModelChange={(input) => {
          if (
            input.pageSize === 25 ||
            input.pageSize === 50 ||
            input.pageSize === 100
          )
            handlePaginationChange({
              page: input.page,
              pageSize: input.pageSize,
            });
        }}
        initialState={{
          pagination: { paginationModel },
          sorting: {
            sortModel: [{ field: sortModel.by, sort: sortModel.direction }],
          },
        }}
        slots={{
          noRowsOverlay: () => (
            <Stack
              alignItems={'center'}
              justifyContent={'center'}
              width={'100%'}
              height={'100%'}
              sx={{ minHeight: '60px' }}
            >
              <Typography variant="body1" fontFamily={FONTS.inter}>
                No transactions found.
              </Typography>
            </Stack>
          ),
        }}
        pageSizeOptions={[25, 50, 100]}
      />
    </div>
  );
};
